export const defaultvalues = {
  MCC: 315,
  MNC: "010",
  TAC: 63,
};

export const UNDER_MAINTENANCE = "UNDER_MAINTENANCE";

export const HARDWARE_MODEL_TYPES = {
  Indoor: "Indoor",
  Flex: "Flex",
  Outdoor: "Outdoor",
};
export enum OBJ_STATUS {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  NA = "NA",
  ASSIGNED = "ASSIGNED",
  UNASSIGNED = "UNASSIGNED",
  USED = "USED",
  NOT_DEPLOYED = "NOT_DEPLOYED",
  DEPLOYING = "DEPLOYING",
  DEPLOYED = "DEPLOYED",
  FAILED = "FAILED",
  DELETING = "DELETING",
  IN_PROGRESS = "IN_PROGRESS",
  MARKED_FOR_REVOKE = "MARKED_FOR_REVOKE",
  MARKED_FOR_DELETE="MARKED_FOR_DELETE",
  REVOKING="REVOKING",
  REVOKED="REVOKED"
}

export const REVOKE = "REVOKE";

export const Types = {
  hardware : "hardware",
  radio : "radio",
} as const;


export const MAP_KEY = import.meta.env.H9_GOOGLE_MAP_API_KEY as string;
function e(t, o) {
  return e = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(r, n) {
    return r.__proto__ = n, r;
  }, e(t, o);
}
function p(t, o) {
  t.prototype = Object.create(o.prototype), t.prototype.constructor = t, e(t, o);
}
export {
  p as _
};

import { CaCertificate } from '~/types/CGWCaCertificate';
import APIService from './APIServices';
import { Certificate } from '~/types/CGWCertificate';

const certificateAPI = new APIService<Certificate>('certificates');
const caCertificateAPI = new APIService<CaCertificate>('certificates/CACerts');

export const certificateService = {
    getCACertificates: () => caCertificateAPI.getAll(),
    getAllCertificates: (objectType = '') => certificateAPI.get(objectType ? `?objectType=${objectType}` : ''),
    createCertificate: (certificate: Certificate) => certificateAPI.post('', certificate),
    deleteCertificate: (id: string) => certificateAPI.delete(`${id}`),
    revokeCertificate:(id:string)=> certificateAPI.post(`${id}/revoke`),
};

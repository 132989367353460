import { jsx as h } from "react/jsx-runtime";
import { useTheme as m, Typography as g } from "@mui/material";
import { forwardRef as y } from "react";
const c = {
  statistics1: "statistics1",
  // Statistics
  statistics2: "statistics2",
  // Statistics 2
  heading1: "h1",
  // Heading 1
  heading2: "h2",
  // Heading 2
  heading3: "h3",
  // Heading 2
  heading4: "h4",
  // Heading 2
  bodyText1: "subtitle1",
  // Body Text
  bodyText2: "subtitle2",
  // Body Text
  bodyText3: "body1",
  // Body Text
  smallText: "caption"
  // Title 1
}, T = {
  statistics1: 700,
  statistics2: 700,
  heading1: 600,
  heading2: 500,
  heading3: 600,
  heading4: 500,
  bodyText1: 600,
  bodyText2: 500,
  bodyText3: 400,
  smallText: 400
}, x = {
  statistics1: "1.5rem",
  statistics2: "0.875rem",
  heading1: "1rem",
  heading2: "1rem",
  heading3: "0.875rem",
  heading4: "0.875rem",
  bodyText1: "0.75rem",
  bodyText2: "0.75rem",
  bodyText3: "0.75rem",
  smallText: "0.625rem"
}, l = {
  statistics1: "2rem",
  statistics2: "1.0625rem",
  heading1: "1.1875rem",
  heading2: "1.1875rem",
  heading3: "1.0625rem",
  heading4: "1.0625rem",
  bodyText1: "0.9375rem",
  bodyText2: "0.9375rem",
  bodyText3: "0.9375rem",
  smallText: "0.75rem"
}, p = {
  statistics1: "Satoshi",
  statistics2: "Satoshi",
  heading1: "'Inter', sans-serif",
  heading2: "'Inter', sans-serif",
  heading3: "'Inter', sans-serif",
  heading4: "'Inter', sans-serif",
  bodyText1: "'Inter', sans-serif",
  bodyText2: "'Inter', sans-serif",
  bodyText3: "'Inter', sans-serif",
  smallText: "'Inter', sans-serif"
}, S = y((e, s) => {
  const i = m(), {
    children: a,
    type: t = "bodyText1",
    color: n = i.palette.grey[900],
    sx: r,
    ...o
  } = e, d = {
    fontWeight: T[t],
    fontSize: x[t],
    lineHeight: l[t],
    fontFamily: p[t],
    letterSpacing: "unset"
  };
  return /* @__PURE__ */ h(
    g,
    {
      variant: c[t],
      sx: { ...d, ...r, color: n },
      ref: s,
      ...o,
      children: a
    }
  );
});
export {
  S as Typography
};

import { jsx as t, Fragment as d, jsxs as r } from "react/jsx-runtime";
import { useState as h } from "react";
import { Grid as e, CircularProgress as b } from "@mui/material";
import { Button as v } from "../atoms/Button/Button.js";
import "../../chunks/Chatbot.style.js";
import "../../chunks/style.module.js";
import "../../chunks/DateTimeSelectorMenu.js";
import "moment-timezone";
import { Typography as n } from "../atoms/Typography/Typography.js";
import { Input as x } from "../atoms/Input/Input.js";
import { asset as f } from "../../assets/icons/index.js";
import { A as B } from "../../chunks/ArrowForward.js";
import "../../chunks/runtime.js";
import "../../chunks/TableContext.js";
import "../ReactTable/SearchFilter.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../chunks/server.browser.js";
import "../../chunks/transform.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import "../organisms/RadarGraph/RadarGraph.js";
import "../organisms/ScoreGauge/ScoreGauge.js";
import "../organisms/SunburstGraph/SunburstGraph.js";
import "../../chunks/ChatbotContext.js";
import "../ReactMap/index.js";
import "../ReactMap/GMapStatic.js";
import "../atoms/FormControl/FormControl.js";
import "../atoms/FormControlLabel/FormControlLabel.js";
import "../../chunks/index.esm.js";
import '../../assets/map.css';/* empty css                */import "../atoms/Grid/Grid.js";
import "../atoms/Paper/Paper.js";
import "../atoms/Box/Box.js";
import "../atoms/Radio/Radio.js";
import "../atoms/RadioGroup/RadioGroup.js";
import "../atoms/Stepper/Stepper.js";
import "../atoms/Step/Step.js";
import "../atoms/StepLabel/StepLabel.js";
const st = ({ errorsComponent: a, loading: m, onLogin: y, onNext: l, onForgotPassword: c, onLicenseAgreement: p, hidePassword: u = !0 }) => {
  const [s, w] = h(""), [g, C] = h("");
  function F(i) {
    i == null || i.preventDefault(), u ? l == null || l(s) : y(s, g);
  }
  return /* @__PURE__ */ t(d, { children: /* @__PURE__ */ t(e, { container: !0, sx: o.main, children: /* @__PURE__ */ r(e, { sx: o.loginContainer, children: [
    /* @__PURE__ */ r(e, { sx: o.topContainer, children: [
      /* @__PURE__ */ t(e, { sx: o.logo, children: /* @__PURE__ */ t("img", { src: f.loginPageLogo, width: "236" }) }),
      /* @__PURE__ */ r(e, { component: "form", onSubmit: F, sx: o.loginForm, children: [
        /* @__PURE__ */ t(n, { sx: o.welcomeBackText, color: "#878787", children: "Welcome back," }),
        /* @__PURE__ */ t(n, { sx: o.loginTitleText, color: "#141414", children: "Log in to your account" }),
        /* @__PURE__ */ t(e, { sx: o.error, children: a }),
        /* @__PURE__ */ t(
          x,
          {
            label: "Email",
            name: "email",
            required: !0,
            id: "username",
            placeholder: "Email",
            sx: o.inputEmail,
            type: "email",
            value: s,
            onChange: (i) => w(i.target.value),
            autoFocus: !0
          }
        ),
        !u && /* @__PURE__ */ t(
          x,
          {
            label: "Password",
            id: "password",
            name: "password",
            required: !0,
            type: "password",
            placeholder: "Password",
            autoFocus: !0,
            sx: o.inputPass,
            value: g,
            onChange: (i) => C(i.target.value)
          }
        ),
        /* @__PURE__ */ t(v, { type: "submit", sx: o.loginButton, disabled: m, children: m ? /* @__PURE__ */ r(d, { children: [
          m && /* @__PURE__ */ t(b, { size: 24 }),
          "  "
        ] }) : /* @__PURE__ */ r(d, { children: [
          /* @__PURE__ */ t(n, { type: "heading3", color: "#FFFFFF", sx: o.loginText, children: "Log In" }),
          /* @__PURE__ */ t(B, {})
        ] }) }),
        /* @__PURE__ */ r("div", { style: o.actions, children: [
          /* @__PURE__ */ t("div", { onClick: () => c == null ? void 0 : c(), children: /* @__PURE__ */ t(n, { type: "heading3", sx: { ...o.pointer, fontWeight: 500, color: (i) => i.palette.grey[600] }, children: "Forgot/Reset Password" }) }),
          !!p && /* @__PURE__ */ t("div", { onClick: () => p == null ? void 0 : p(), children: /* @__PURE__ */ t(n, { type: "heading3", sx: { ...o.pointer, fontWeight: 500, color: (i) => i.palette.primary.main }, children: "License Agreement" }) })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ r(e, { sx: o.noAccount, children: [
      /* @__PURE__ */ t(n, { type: "heading4", color: "#141414", sx: { fontWeight: 400 }, children: "Don’t have an account?" }),
      /* @__PURE__ */ t("div", { onClick: () => {
        window.open("mailto:support@highway9.com");
      }, children: /* @__PURE__ */ t(n, { type: "heading3", sx: {
        color: (i) => i.palette.primary.main,
        ...o.pointer
      }, children: "Contact Support" }) })
    ] })
  ] }) }) });
}, o = {
  main: (a) => ({
    width: "100vw",
    height: "100vh",
    overflow: "none",
    backgroundColor: a.palette.primary.main,
    backgroundImage: `url(${f.loginPageBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }),
  logo: {
    textAlign: "center",
    marginBottom: "40px"
  },
  loginContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    width: "100%",
    mx: "8px",
    maxHeight: "95vh",
    maxWidth: "500px"
  },
  topContainer: {
    padding: "3rem 3.5rem 2rem"
  },
  loginForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    gap: "8px"
  },
  noAccount: {
    borderTop: "1px solid #EFEFEF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: "32px",
    gap: "8px"
  },
  pointer: {
    cursor: "pointer"
  },
  loginButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "100%",
    marginBottom: "16px",
    "&::before ": {
      content: "''",
      width: 0
    }
  },
  welcomeBackText: {
    fontSize: "1rem",
    marginBottom: "8px"
  },
  loginTitleText: {
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "8px"
  },
  loginText: {
    textAlign: "center"
  },
  inputEmail: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  inputPass: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  error: {
    marginBottom: "1rem",
    marginTop: "0.5rem",
    width: "100%",
    "& .MuiAlert-root": {
      borderRadius: "8px"
    }
  }
};
export {
  st as default
};

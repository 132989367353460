/**
 Copyright Highway9 Networks Inc.
 */
 import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
 import { RootState } from "..";
 import { Certificate } from "~/types/CGWCertificate";
 import { certificateService } from "~/services/CGWCertificates-service";
import { CaCertificate } from "~/types/CGWCaCertificate";

 type initState = {
   open: boolean;
   current: Certificate | null;
   data: Certificate[];
   caCertData: CaCertificate[] ;
   currentCaCert: CaCertificate | null
 };

 const initialState: initState = {
   open: false,
   current: null,
   data: [],
   caCertData:[],
   currentCaCert:null
 };

 export const fetchCertificates = createAsyncThunk("certificate/fetchCertificates", async (objectType:string) => {
   const data = await certificateService.getAllCertificates(objectType);
   return data;
 });
 export const fetchCACertificates = createAsyncThunk("certificate/fetchCACertificates", async () => {
  const data = await certificateService.getCACertificates();
  return data;
});
 const CertificateSlice = createSlice({
   name: "Certificate",
   initialState,
   reducers: {
     setOpen: (state, action: PayloadAction<boolean>) => {
       state.open = action.payload;
     },
     setValues: (state, action: PayloadAction<Certificate | null>) => {
       state.current = action.payload;
     },
     delete: (state, action: PayloadAction<string>) => {
       const index = state.data.findIndex((item) => item.id === action.payload);
       state.data = state.data.filter((_, i) => i !== index);
     },
     setData: (state, action: PayloadAction<Certificate[]>) => {
       state.data = action.payload;
       const current = action.payload.find((item) => item.id === state.current?.id);
       if (current) {
         state.current = current;
       }
     },
     addData: (state, action: PayloadAction<Certificate>) => {
       state.data.push(action.payload);
     },
     setCurrentCertificate: (state, action: PayloadAction<Certificate | null>) => {
      state.current = action.payload;
    },
     setCurrentCaCert: (state, action: PayloadAction<CaCertificate | null>) => {
      state.currentCaCert = action.payload;
    },
   },
   extraReducers: (builder) => {
     builder.addCase(fetchCertificates.fulfilled, (state, action) => {
       state.data = action.payload;

       // Sync current with latest data
       if (state.current && !state.open) {
         const current = action.payload.find((item) => item.id === state.current?.id);
         if (current) {
           state.current = current;
         }
       }
     });
     builder.addCase(fetchCACertificates.fulfilled, (state, action) => {
      state.caCertData = action.payload;
      // Sync current CA certificate with latest data
      if (state.currentCaCert) {
        const currentCa = action.payload.find((item) => item.name === state.currentCaCert?.name);
        if (currentCa) {
          state.currentCaCert = currentCa;
        }
      }
    });
   },

 });

 export const CertificateActions = CertificateSlice.actions;
 export default CertificateSlice.reducer;
 export const CertificateState = (state: RootState) => state.certificate.current;
 export const CertificateData = (state: RootState) => state.certificate.data;
 export const CertificateOpen = (state: RootState) => state.certificate.open;
 export const CertificateCaData = (state: RootState) => state.certificate.caCertData;
 export const CertificateCurrentCa = (state: RootState) => state.certificate.currentCaCert;
/**
 * Copyright Highway9 Networks Inc.
 */
import React, { useEffect, type JSX } from 'react';
import { Route, Redirect, } from 'react-router-dom';
import { authenticationService } from '../services';
import { routes } from '~/constants/routes';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { Backdrop, CircularProgress } from '@mui/material';
import { fetchTenants } from '~/store/context/tenantSlice';

type Props =
    {
        component: (props: any) => JSX.Element,
        path: string,
    }

export const AuthRouter = ({ component: Component, path }: Props) => {
    const url = window.location.href;
    sessionStorage.setItem("url", url);
    const currentUser = authenticationService.currentUserValue;
    const routesArray = routes()

    const isTenantLoading = useAppSelector((state) => state.tenant.loading);
    const dispatch = useAppDispatch()

    useEffect(()=> {
        if(authenticationService.isTenant()){
            dispatch(fetchTenants())
        }
    }, [dispatch])
    // const currentUser = JSON.parse(localStorage.getItem("currentUser") || 'null')
    return (
        <Route
            path={path}
            render={(props) => {

                if (!currentUser) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                } else {
                    // check if the user is tenant or not and redirect to the appropriate page
                    if (authenticationService.isTenant()) {
                        if (isTenantLoading) {
                            return <Backdrop sx={{ color: "#fff", zIndex: "9999" }} open={true}>
                                <CircularProgress />
                            </Backdrop>
                        } else {
                            if (!currentUser.isEULAAccepted) return <Redirect to={{ pathname: "/eula" }} />;
                        }
                    }
                    // check if the user is operator or not and redirect to the appropriate page
                    if (!authenticationService.isOperator()) {
                        const path = window.location.pathname;
                        const currentRoute = routesArray.find((route) => route.path === path);
                        if (currentRoute?.hidden) {
                            window.location.replace("/");
                        }
                    }
                }
                // authorised so return component
                return <Component {...props} />
            }} />)
}

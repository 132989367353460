import * as React from 'react';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from '~/navigation/Layout';
import { authenticationService, partnerService, tenantService } from '~/services';
import { Checkbox, SxProps, Theme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { fetchTenants, tenantData } from '~/store/context/tenantSlice';
import { useHistory } from 'react-router-dom';
import { fetchPartners, partnerData } from '~/store/context/partnerSlice';
import TNCDocument from '~/components/TNCDocument';

const styles = {
    mainPaper: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        m: 0,
        p: 2,
        borderWidth: 0,
        borderTopWidth: 1,
        display: "flex",
        justifyContent: "center",
    },
} satisfies Record<string, SxProps<Theme>>;

export default function TNCPanel() {
    const currentUser = authenticationService.currentUserValue
    const [disableSubmit, setDisableSubmit] = React.useState(true)
    const [isAgree, setIsAgree] = React.useState(false)
    const tenantsData = useAppSelector(tenantData);
    const partnersData = useAppSelector(partnerData);
    const history = useHistory()
    const dispatch = useAppDispatch()
    const checkboxHandler = () => {
        setIsAgree(!isAgree)
    }
    const handleScroll = (e: React.MouseEvent<HTMLInputElement>) => {
        const clientHeightWithMarginOfError = e.currentTarget.clientHeight + 2; // We are relaxing the trigger zone by 2px
        const bottom = Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) <= clientHeightWithMarginOfError;
        if (bottom) setDisableSubmit(false)
    }
    const handleSubmit = async () => {
        try {
            if (authenticationService.isPartner()) {
                const payload = {
                    ...partnersData[0],
                    eulaAccepted: true
                }
                await partnerService.updatePartner(payload)
                await dispatch(fetchPartners());
            } else {
                const payload = {
                    ...tenantsData[0],
                    eulaAccepted: true
                }
                await tenantService.updateTenant(payload)
                await dispatch(fetchTenants())
            }
            history.push('/')
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        if (authenticationService.isPartner() && (!partnersData || partnersData.length <= 0)) {
            dispatch(fetchPartners())
        } else if (!tenantsData || tenantsData.length <= 0) {
            dispatch(fetchTenants())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <Layout hideNavBar={true}>
                <TNCDocument handleScroll={handleScroll} />
                <TrapFocus open disableAutoFocus disableEnforceFocus>
                    <Fade appear={false} in={true}>
                        <Paper
                            role="dialog"
                            aria-modal="false"
                            aria-label="Cookie banner"
                            square
                            variant="outlined"
                            tabIndex={-1}
                            sx={styles.mainPaper}
                        >
                            {currentUser && !currentUser.isEULAAccepted ? <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="right"
                                gap={2}
                            >
                                <Box
                                    sx={{

                                        alignSelf: { xs: 'flex-start', sm: 'center' },
                                    }}
                                >
                                    <Typography fontWeight="bold" sx={{ color: disableSubmit ? "grey" : "black" }}><Checkbox disabled={disableSubmit} onChange={checkboxHandler} checked={isAgree} />  I agree</Typography>

                                </Box>
                                <Stack
                                    gap={2}
                                    direction={{
                                        xs: 'row-reverse',
                                        sm: 'row',
                                    }}
                                    sx={{
                                        flexShrink: 0,
                                        alignSelf: { xs: 'flex-end', sm: 'center' },
                                    }}
                                >
                                    <Button size="small" variant="contained" disabled={!isAgree} onClick={handleSubmit}>
                                        Submit
                                    </Button>

                                </Stack>
                            </Stack> : <Button size="small" variant="contained" onClick={() => {
                                history.replace("/")
                                console.log("DialogBox is Closed");

                            }}>
                                Close
                            </Button>}
                        </Paper>
                    </Fade>
                </TrapFocus>
            </Layout>
        </React.Fragment>
    );
}
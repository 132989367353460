var I = Object.defineProperty;
var k = (n, i, r) => i in n ? I(n, i, { enumerable: !0, configurable: !0, writable: !0, value: r }) : n[i] = r;
var T = (n, i, r) => (k(n, typeof i != "symbol" ? i + "" : i, r), r);
import { P as s } from "./DefaultPropsProvider.js";
import * as N from "react";
import x from "react";
import { _ as w } from "./inheritsLoose.js";
import g from "react-dom";
function M(n, i) {
  return process.env.NODE_ENV === "production" ? () => null : function(...o) {
    return n(...o) || i(...o);
  };
}
function P(n) {
  const {
    prototype: i = {}
  } = n;
  return !!i.isReactComponent;
}
function L(n, i, r, o, e) {
  const t = n[i], a = e || i;
  if (t == null || // When server-side rendering React doesn't warn either.
  // This is not an accurate check for SSR.
  // This is only in place for emotion compat.
  // TODO: Revisit once https://github.com/facebook/react/issues/20047 is resolved.
  typeof window > "u")
    return null;
  let u;
  return typeof t == "function" && !P(t) && (u = "Did you accidentally provide a plain function component instead?"), u !== void 0 ? new Error(`Invalid ${o} \`${a}\` supplied to \`${r}\`. Expected an element type that can hold a ref. ${u} For more information see https://mui.com/r/caveat-with-refs-guide`) : null;
}
const z = M(s.elementType, L);
function _(n, i) {
  typeof n == "function" ? n(i) : n && (n.current = i);
}
function B(...n) {
  return N.useMemo(() => n.every((i) => i == null) ? null : (i) => {
    n.forEach((r) => {
      _(r, i);
    });
  }, n);
}
const R = {};
function U(n, i) {
  const r = N.useRef(R);
  return r.current === R && (r.current = n(i)), r;
}
const A = [];
function G(n) {
  N.useEffect(n, A);
}
class y {
  constructor() {
    T(this, "currentId", null);
    T(this, "clear", () => {
      this.currentId !== null && (clearTimeout(this.currentId), this.currentId = null);
    });
    T(this, "disposeEffect", () => this.clear);
  }
  static create() {
    return new y();
  }
  /**
   * Executes `fn` after `delay`, clearing any previously scheduled call.
   */
  start(i, r) {
    this.clear(), this.currentId = setTimeout(() => {
      this.currentId = null, r();
    }, i);
  }
}
function Y() {
  const n = U(y.create).current;
  return G(n.disposeEffect), n;
}
function $(n, i) {
  if (n == null)
    return {};
  var r = {};
  for (var o in n)
    if ({}.hasOwnProperty.call(n, o)) {
      if (i.indexOf(o) !== -1)
        continue;
      r[o] = n[o];
    }
  return r;
}
const D = {
  disabled: !1
};
var q = process.env.NODE_ENV !== "production" ? s.oneOfType([s.number, s.shape({
  enter: s.number,
  exit: s.number,
  appear: s.number
}).isRequired]) : null;
process.env.NODE_ENV !== "production" && s.oneOfType([s.string, s.shape({
  enter: s.string,
  exit: s.string,
  active: s.string
}), s.shape({
  enter: s.string,
  enterDone: s.string,
  enterActive: s.string,
  exit: s.string,
  exitDone: s.string,
  exitActive: s.string
})]);
const S = x.createContext(null);
var F = function(i) {
  return i.scrollTop;
}, v = "unmounted", f = "exited", p = "entering", h = "entered", b = "exiting", l = /* @__PURE__ */ function(n) {
  w(i, n);
  function i(o, e) {
    var t;
    t = n.call(this, o, e) || this;
    var a = e, u = a && !a.isMounting ? o.enter : o.appear, c;
    return t.appearStatus = null, o.in ? u ? (c = f, t.appearStatus = p) : c = h : o.unmountOnExit || o.mountOnEnter ? c = v : c = f, t.state = {
      status: c
    }, t.nextCallback = null, t;
  }
  i.getDerivedStateFromProps = function(e, t) {
    var a = e.in;
    return a && t.status === v ? {
      status: f
    } : null;
  };
  var r = i.prototype;
  return r.componentDidMount = function() {
    this.updateStatus(!0, this.appearStatus);
  }, r.componentDidUpdate = function(e) {
    var t = null;
    if (e !== this.props) {
      var a = this.state.status;
      this.props.in ? a !== p && a !== h && (t = p) : (a === p || a === h) && (t = b);
    }
    this.updateStatus(!1, t);
  }, r.componentWillUnmount = function() {
    this.cancelNextCallback();
  }, r.getTimeouts = function() {
    var e = this.props.timeout, t, a, u;
    return t = a = u = e, e != null && typeof e != "number" && (t = e.exit, a = e.enter, u = e.appear !== void 0 ? e.appear : a), {
      exit: t,
      enter: a,
      appear: u
    };
  }, r.updateStatus = function(e, t) {
    if (e === void 0 && (e = !1), t !== null)
      if (this.cancelNextCallback(), t === p) {
        if (this.props.unmountOnExit || this.props.mountOnEnter) {
          var a = this.props.nodeRef ? this.props.nodeRef.current : g.findDOMNode(this);
          a && F(a);
        }
        this.performEnter(e);
      } else
        this.performExit();
    else
      this.props.unmountOnExit && this.state.status === f && this.setState({
        status: v
      });
  }, r.performEnter = function(e) {
    var t = this, a = this.props.enter, u = this.context ? this.context.isMounting : e, c = this.props.nodeRef ? [u] : [g.findDOMNode(this), u], d = c[0], m = c[1], O = this.getTimeouts(), C = u ? O.appear : O.enter;
    if (!e && !a || D.disabled) {
      this.safeSetState({
        status: h
      }, function() {
        t.props.onEntered(d);
      });
      return;
    }
    this.props.onEnter(d, m), this.safeSetState({
      status: p
    }, function() {
      t.props.onEntering(d, m), t.onTransitionEnd(C, function() {
        t.safeSetState({
          status: h
        }, function() {
          t.props.onEntered(d, m);
        });
      });
    });
  }, r.performExit = function() {
    var e = this, t = this.props.exit, a = this.getTimeouts(), u = this.props.nodeRef ? void 0 : g.findDOMNode(this);
    if (!t || D.disabled) {
      this.safeSetState({
        status: f
      }, function() {
        e.props.onExited(u);
      });
      return;
    }
    this.props.onExit(u), this.safeSetState({
      status: b
    }, function() {
      e.props.onExiting(u), e.onTransitionEnd(a.exit, function() {
        e.safeSetState({
          status: f
        }, function() {
          e.props.onExited(u);
        });
      });
    });
  }, r.cancelNextCallback = function() {
    this.nextCallback !== null && (this.nextCallback.cancel(), this.nextCallback = null);
  }, r.safeSetState = function(e, t) {
    t = this.setNextCallback(t), this.setState(e, t);
  }, r.setNextCallback = function(e) {
    var t = this, a = !0;
    return this.nextCallback = function(u) {
      a && (a = !1, t.nextCallback = null, e(u));
    }, this.nextCallback.cancel = function() {
      a = !1;
    }, this.nextCallback;
  }, r.onTransitionEnd = function(e, t) {
    this.setNextCallback(t);
    var a = this.props.nodeRef ? this.props.nodeRef.current : g.findDOMNode(this), u = e == null && !this.props.addEndListener;
    if (!a || u) {
      setTimeout(this.nextCallback, 0);
      return;
    }
    if (this.props.addEndListener) {
      var c = this.props.nodeRef ? [this.nextCallback] : [a, this.nextCallback], d = c[0], m = c[1];
      this.props.addEndListener(d, m);
    }
    e != null && setTimeout(this.nextCallback, e);
  }, r.render = function() {
    var e = this.state.status;
    if (e === v)
      return null;
    var t = this.props, a = t.children;
    t.in, t.mountOnEnter, t.unmountOnExit, t.appear, t.enter, t.exit, t.timeout, t.addEndListener, t.onEnter, t.onEntering, t.onEntered, t.onExit, t.onExiting, t.onExited, t.nodeRef;
    var u = $(t, ["children", "in", "mountOnEnter", "unmountOnExit", "appear", "enter", "exit", "timeout", "addEndListener", "onEnter", "onEntering", "onEntered", "onExit", "onExiting", "onExited", "nodeRef"]);
    return (
      // allows for nested Transitions
      /* @__PURE__ */ x.createElement(S.Provider, {
        value: null
      }, typeof a == "function" ? a(e, u) : x.cloneElement(x.Children.only(a), u))
    );
  }, i;
}(x.Component);
l.contextType = S;
l.propTypes = process.env.NODE_ENV !== "production" ? {
  /**
   * A React reference to DOM element that need to transition:
   * https://stackoverflow.com/a/51127130/4671932
   *
   *   - When `nodeRef` prop is used, `node` is not passed to callback functions
   *      (e.g. `onEnter`) because user already has direct access to the node.
   *   - When changing `key` prop of `Transition` in a `TransitionGroup` a new
   *     `nodeRef` need to be provided to `Transition` with changed `key` prop
   *     (see
   *     [test/CSSTransition-test.js](https://github.com/reactjs/react-transition-group/blob/13435f897b3ab71f6e19d724f145596f5910581c/test/CSSTransition-test.js#L362-L437)).
   */
  nodeRef: s.shape({
    current: typeof Element > "u" ? s.any : function(n, i, r, o, e, t) {
      var a = n[i];
      return s.instanceOf(a && "ownerDocument" in a ? a.ownerDocument.defaultView.Element : Element)(n, i, r, o, e, t);
    }
  }),
  /**
   * A `function` child can be used instead of a React element. This function is
   * called with the current transition status (`'entering'`, `'entered'`,
   * `'exiting'`, `'exited'`), which can be used to apply context
   * specific props to a component.
   *
   * ```jsx
   * <Transition in={this.state.in} timeout={150}>
   *   {state => (
   *     <MyComponent className={`fade fade-${state}`} />
   *   )}
   * </Transition>
   * ```
   */
  children: s.oneOfType([s.func.isRequired, s.element.isRequired]).isRequired,
  /**
   * Show the component; triggers the enter or exit states
   */
  in: s.bool,
  /**
   * By default the child component is mounted immediately along with
   * the parent `Transition` component. If you want to "lazy mount" the component on the
   * first `in={true}` you can set `mountOnEnter`. After the first enter transition the component will stay
   * mounted, even on "exited", unless you also specify `unmountOnExit`.
   */
  mountOnEnter: s.bool,
  /**
   * By default the child component stays mounted after it reaches the `'exited'` state.
   * Set `unmountOnExit` if you'd prefer to unmount the component after it finishes exiting.
   */
  unmountOnExit: s.bool,
  /**
   * By default the child component does not perform the enter transition when
   * it first mounts, regardless of the value of `in`. If you want this
   * behavior, set both `appear` and `in` to `true`.
   *
   * > **Note**: there are no special appear states like `appearing`/`appeared`, this prop
   * > only adds an additional enter transition. However, in the
   * > `<CSSTransition>` component that first enter transition does result in
   * > additional `.appear-*` classes, that way you can choose to style it
   * > differently.
   */
  appear: s.bool,
  /**
   * Enable or disable enter transitions.
   */
  enter: s.bool,
  /**
   * Enable or disable exit transitions.
   */
  exit: s.bool,
  /**
   * The duration of the transition, in milliseconds.
   * Required unless `addEndListener` is provided.
   *
   * You may specify a single timeout for all transitions:
   *
   * ```jsx
   * timeout={500}
   * ```
   *
   * or individually:
   *
   * ```jsx
   * timeout={{
   *  appear: 500,
   *  enter: 300,
   *  exit: 500,
   * }}
   * ```
   *
   * - `appear` defaults to the value of `enter`
   * - `enter` defaults to `0`
   * - `exit` defaults to `0`
   *
   * @type {number | { enter?: number, exit?: number, appear?: number }}
   */
  timeout: function(i) {
    var r = q;
    i.addEndListener || (r = r.isRequired);
    for (var o = arguments.length, e = new Array(o > 1 ? o - 1 : 0), t = 1; t < o; t++)
      e[t - 1] = arguments[t];
    return r.apply(void 0, [i].concat(e));
  },
  /**
   * Add a custom transition end trigger. Called with the transitioning
   * DOM node and a `done` callback. Allows for more fine grained transition end
   * logic. Timeouts are still used as a fallback if provided.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * ```jsx
   * addEndListener={(node, done) => {
   *   // use the css transitionend event to mark the finish of a transition
   *   node.addEventListener('transitionend', done, false);
   * }}
   * ```
   */
  addEndListener: s.func,
  /**
   * Callback fired before the "entering" status is applied. An extra parameter
   * `isAppearing` is supplied to indicate if the enter stage is occurring on the initial mount
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement, isAppearing: bool) -> void
   */
  onEnter: s.func,
  /**
   * Callback fired after the "entering" status is applied. An extra parameter
   * `isAppearing` is supplied to indicate if the enter stage is occurring on the initial mount
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement, isAppearing: bool)
   */
  onEntering: s.func,
  /**
   * Callback fired after the "entered" status is applied. An extra parameter
   * `isAppearing` is supplied to indicate if the enter stage is occurring on the initial mount
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement, isAppearing: bool) -> void
   */
  onEntered: s.func,
  /**
   * Callback fired before the "exiting" status is applied.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement) -> void
   */
  onExit: s.func,
  /**
   * Callback fired after the "exiting" status is applied.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement) -> void
   */
  onExiting: s.func,
  /**
   * Callback fired after the "exited" status is applied.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed
   *
   * @type Function(node: HtmlElement) -> void
   */
  onExited: s.func
} : {};
function E() {
}
l.defaultProps = {
  in: !1,
  mountOnEnter: !1,
  unmountOnExit: !1,
  appear: !1,
  enter: !0,
  exit: !0,
  onEnter: E,
  onEntering: E,
  onEntered: E,
  onExit: E,
  onExiting: E,
  onExited: E
};
l.UNMOUNTED = v;
l.EXITED = f;
l.ENTERING = p;
l.ENTERED = h;
l.EXITING = b;
const Z = l, J = (n) => n.scrollTop;
function K(n, i) {
  const {
    timeout: r,
    easing: o,
    style: e = {}
  } = n;
  return {
    duration: e.transitionDuration ?? (typeof r == "number" ? r : r[i.mode] || 0),
    easing: e.transitionTimingFunction ?? (typeof o == "object" ? o[i.mode] : o),
    delay: e.transitionDelay
  };
}
export {
  Z as T,
  $ as _,
  Y as a,
  S as b,
  M as c,
  U as d,
  z as e,
  K as g,
  J as r,
  _ as s,
  B as u
};

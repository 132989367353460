/**
 * Copyright Highway9 Networks Inc.
 */
import { Icons } from "../components/GraphicalResources";
import { text } from "../components/TextResources";
import { authenticationService } from "../services";

import type { JSX } from "react";

export const TENANTS = "/tenants";
export const ORDER_MANAGEMENT = "/orderManagement";
export const VIRTUAL_MOBILITY_CLOUDS = "/";
export const HARDWARE_MANAGEMENT = "/hardwareManagement";
export const SOFTWARE_RELEASE = "/softwareRelease";
export const SIM_MANAGEMENT = "/simManagement";
export const ECI_MANAGEMENT = "/eciManagement";
export const USER_MANAGEMENT = "/userManagement";
export const AUDITLOGS = "/auditLogs";
export const ADMINISTRATION = "/administration";
export const LICENSE_AGREEMENT = "/eula";
export const API_DOCS = "/api/v1/saas/docs";

type Route = {
  name: string;
  path: string;
  icon: JSX.Element;
  hidden?: boolean;
  dividerAfter?: boolean;
};
// Changed this to function to update the hidden value without reload the page
export const routes = () =>
  [
    {
      name: text.tenants!,
      path: TENANTS,
      icon: Icons.tenant,
      hidden: !authenticationService.isOperator(),
    },
    {
      name: text.orderManagement!,
      path: ORDER_MANAGEMENT,
      icon: Icons.orderManagement,
      hidden:authenticationService.isPartner(),
    },
    {
      name: text.VMCs!,
      path: VIRTUAL_MOBILITY_CLOUDS,
      icon: Icons.serviceInstance,
      dividerAfter: true,
    },
    {
      name: text.hardwareManagement!,
      path: HARDWARE_MANAGEMENT,
      icon: Icons.hardwareInventory,
      hidden: !authenticationService.isOperator(),
    },
    {
      name: text.releases!,
      path: SOFTWARE_RELEASE,
      icon: Icons.application,
      hidden: !authenticationService.isOperator(),
    },
    {
      name: text.simManagement!,
      path: SIM_MANAGEMENT,
      icon: Icons.simManagement,
      hidden: !authenticationService.isOperator(),
    },
    {
      name: text.ECIManagement!,
      path: ECI_MANAGEMENT,
      icon: Icons.ECIManagement,
      hidden: !authenticationService.isOperator(),
      dividerAfter: authenticationService.isOperator(),
    },
    {
      name: text.Administration!,
      path: ADMINISTRATION,
      icon: Icons.Administration,
    },
    { name: text.userManagement!, path: USER_MANAGEMENT, icon: Icons.userManagement },
    {
      name: text.auditLog!,
      path: AUDITLOGS,
      icon: Icons.logs,
    },
  ] as Route[];

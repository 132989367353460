import { b as l } from "./DefaultPropsProvider.js";
function O(s) {
  return typeof s == "string";
}
function A(s, c, e) {
  return s === void 0 || O(s) ? c : {
    ...c,
    ownerState: {
      ...c.ownerState,
      ...e
    }
  };
}
function k(s, c = []) {
  if (s === void 0)
    return {};
  const e = {};
  return Object.keys(s).filter((n) => n.match(/^on[A-Z]/) && typeof s[n] == "function" && !c.includes(n)).forEach((n) => {
    e[n] = s[n];
  }), e;
}
function a(s) {
  if (s === void 0)
    return {};
  const c = {};
  return Object.keys(s).filter((e) => !(e.match(/^on[A-Z]/) && typeof s[e] == "function")).forEach((e) => {
    c[e] = s[e];
  }), c;
}
function R(s) {
  const {
    getSlotProps: c,
    additionalProps: e,
    externalSlotProps: n,
    externalForwardedProps: t,
    className: i
  } = s;
  if (!c) {
    const g = l(e == null ? void 0 : e.className, i, t == null ? void 0 : t.className, n == null ? void 0 : n.className), h = {
      ...e == null ? void 0 : e.style,
      ...t == null ? void 0 : t.style,
      ...n == null ? void 0 : n.style
    }, m = {
      ...e,
      ...t,
      ...n
    };
    return g.length > 0 && (m.className = g), Object.keys(h).length > 0 && (m.style = h), {
      props: m,
      internalRef: void 0
    };
  }
  const v = k({
    ...t,
    ...n
  }), E = a(n), H = a(t), f = c(v), y = l(f == null ? void 0 : f.className, e == null ? void 0 : e.className, i, t == null ? void 0 : t.className, n == null ? void 0 : n.className), N = {
    ...f == null ? void 0 : f.style,
    ...e == null ? void 0 : e.style,
    ...t == null ? void 0 : t.style,
    ...n == null ? void 0 : n.style
  }, u = {
    ...f,
    ...e,
    ...H,
    ...E
  };
  return y.length > 0 && (u.className = y), Object.keys(N).length > 0 && (u.style = N), {
    props: u,
    internalRef: f.ref
  };
}
function W(s, c, e) {
  return typeof s == "function" ? s(c, e) : s;
}
export {
  A as a,
  k as e,
  R as m,
  W as r
};

import { jsx as o, jsxs as y } from "react/jsx-runtime";
import { c as x } from "../../../chunks/createSvgIcon.js";
import "../../../chunks/Chatbot.style.js";
import { Box as g, OutlinedInput as c, InputAdornment as b, IconButton as M } from "@mui/material";
import { useState as I } from "react";
import "../FormControl/FormControl.js";
import "../FormControlLabel/FormControlLabel.js";
import "../../../chunks/index.esm.js";
import "../../../chunks/server.browser.js";
import '../../../assets/map.css';/* empty css                   */import "../Grid/Grid.js";
import "../Paper/Paper.js";
import "../Box/Box.js";
import "../Radio/Radio.js";
import "../RadioGroup/RadioGroup.js";
import { Typography as h } from "../Typography/Typography.js";
import "../Stepper/Stepper.js";
import "../Step/Step.js";
import "../StepLabel/StepLabel.js";
import "../../../chunks/style.module.js";
import "../../../chunks/DateTimeSelectorMenu.js";
import "moment-timezone";
import "../../../chunks/runtime.js";
import "../../../chunks/TableContext.js";
import "../../ReactTable/SearchFilter.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../chunks/transform.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import "../../organisms/RadarGraph/RadarGraph.js";
import "../../organisms/ScoreGauge/ScoreGauge.js";
import "../../organisms/SunburstGraph/SunburstGraph.js";
import "../../../chunks/ChatbotContext.js";
import "../../ReactMap/index.js";
import "../../ReactMap/GMapStatic.js";
const S = x(/* @__PURE__ */ o("path", {
  d: "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3"
}), "Visibility"), A = x(/* @__PURE__ */ o("path", {
  d: "M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7M2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2m4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3z"
}), "VisibilityOff");
function ho(i) {
  const {
    variant: n = "outlined",
    width: s,
    type: l = "text",
    height: d,
    sx: t,
    label: m,
    error: e,
    errorText: a,
    ...r
  } = i, [p, f] = I(!1);
  return /* @__PURE__ */ y(g, { sx: { display: "flex", flexDirection: "column", gap: "4px", width: "100%" }, children: [
    m && /* @__PURE__ */ o(h, { sx: C, children: m }),
    l === "password" ? /* @__PURE__ */ o(
      c,
      {
        id: "outlined-adornment-password",
        type: p ? "text" : "password",
        error: e,
        endAdornment: /* @__PURE__ */ o(b, { position: "end", children: /* @__PURE__ */ o(
          M,
          {
            "aria-label": "toggle password visibility",
            onClick: () => {
              f(!p);
            },
            onMouseDown: (w) => {
              w.preventDefault();
            },
            edge: "end",
            children: p ? /* @__PURE__ */ o(A, {}) : /* @__PURE__ */ o(S, {})
          }
        ) }),
        sx: [
          { width: s, height: d },
          u[n],
          ...Array.isArray(t) ? t : [t]
        ],
        placeholder: r.placeholder ? r.placeholder : "",
        ...r
      }
    ) : /* @__PURE__ */ o(
      c,
      {
        sx: [
          {
            width: s,
            height: d
          },
          u[n],
          ...Array.isArray(t) ? t : [t]
        ],
        error: e,
        type: l,
        ...r
      }
    ),
    e && a && /* @__PURE__ */ o(h, { sx: P, children: a })
  ] });
}
const u = {
  outlined: (i) => ({
    borderColor: `${i.palette.grey[600]}`,
    borderRadius: "8px",
    textTransform: "none",
    gap: 3,
    fontSize: "14px",
    marginTop: "2px !important",
    ".MuiInputBase-root": {
      borderRadius: 2
    },
    "&>.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#14141499"
    }
  }),
  filled: () => ({}),
  standard: () => ({})
}, C = {
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14px",
  color: "#5A5A5A"
}, P = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  color: "#d32f2f"
};
export {
  ho as Input
};

/**
 * Copyright Highway9 Networks Inc.
 */
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { TextField } from '@mui/material';
import { text } from '../components/TextResources';
import { authenticationService } from '../services';
import useErrorsAndWarnings from '../hooks/useErrorsAndWarnings';
import ErrorsComponent from '../components/ErrorsComponent';
import AlertDialog from '../components/AlertDialog';
import { DialogBox ,DialogActions,DialogTitle,DialogContent} from '~/styles/Dialog';

import type { JSX } from "react";

interface ForgotPasswordProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
}

export  default function ForgotPassword({ open, setOpen}: ForgotPasswordProps): JSX.Element {
  const [email, setEmail] = React.useState("");
  const { errors, warnings, setErrorsAndWarnings, closeAlert, closeAll } = useErrorsAndWarnings();
  const [submitted, setSubmitted] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function handleSubmit() {
    authenticationService.forgetPassword(email)
      .then(
        user => {
          console.log('ForgotPassword: user: ' + JSON.stringify(user));
          setSubmitted(true);
          closeAll();
        },
        error => {
          setErrorsAndWarnings(error.errors, error.warnings);
        }
      );
  }

  return (
    <div>
      <DialogBox
        data-testid="alert-dialog-container"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Reset Password
        </DialogTitle>
        <DialogContent>
          <ErrorsComponent errorsData={errors} warningsData={warnings} closeAll={closeAll} closeAlert={closeAlert} />
          <DialogContentText id="alert-dialog-description">
            Please enter your email address and we will send you a link to reset your password.
            <br /><br />
            <TextField variant="standard" id="username" type='email' label={text.email} fullWidth autoFocus={true} value={email} onInput={(e: React.ChangeEvent<HTMLInputElement>)  => setEmail(e.target.value)} /><br />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="alert-dialog-cancel-btn" onClick={() => {
            handleClose();
          }}>Cancel</Button>
          <Button data-testid="alert-dialog-confirm-btn" color={'primary'} onClick={() => {
            handleSubmit();
            handleClose();
          }} autoFocus>Submit</Button>
        </DialogActions>
      </DialogBox>

      <AlertDialog open={submitted} setOpen={setSubmitted} title='Forget Password' message={`If this is a valid username, you will receive an email with the link shortly to reset the password. The reset-password link will be valid for 72 hours.`} />
    </div>
  );
}

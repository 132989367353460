import * as h from "react";
import { jsxs as w, jsx as b } from "react/jsx-runtime";
import { g as C, a as T, s as N, c as z, m as O, u as R, b as $, P as e, d as j } from "./DefaultPropsProvider.js";
function E(o) {
  return C("MuiSvgIcon", o);
}
T("MuiSvgIcon", ["root", "colorPrimary", "colorSecondary", "colorAction", "colorError", "colorDisabled", "fontSizeInherit", "fontSizeSmall", "fontSizeMedium", "fontSizeLarge"]);
const A = (o) => {
  const {
    color: r,
    fontSize: n,
    classes: s
  } = o, t = {
    root: ["root", r !== "inherit" && `color${z(r)}`, `fontSize${z(n)}`]
  };
  return j(t, E, s);
}, B = N("svg", {
  name: "MuiSvgIcon",
  slot: "Root",
  overridesResolver: (o, r) => {
    const {
      ownerState: n
    } = o;
    return [r.root, n.color !== "inherit" && r[`color${z(n.color)}`], r[`fontSize${z(n.fontSize)}`]];
  }
})(O(({
  theme: o
}) => {
  var r, n, s, t, l, u, a, g, p, d, c, m, y, f;
  return {
    userSelect: "none",
    width: "1em",
    height: "1em",
    display: "inline-block",
    flexShrink: 0,
    transition: (t = (r = o.transitions) == null ? void 0 : r.create) == null ? void 0 : t.call(r, "fill", {
      duration: (s = (n = (o.vars ?? o).transitions) == null ? void 0 : n.duration) == null ? void 0 : s.shorter
    }),
    variants: [
      {
        props: (i) => !i.hasSvgAsChild,
        style: {
          // the <svg> will define the property that has `currentColor`
          // for example heroicons uses fill="none" and stroke="currentColor"
          fill: "currentColor"
        }
      },
      {
        props: {
          fontSize: "inherit"
        },
        style: {
          fontSize: "inherit"
        }
      },
      {
        props: {
          fontSize: "small"
        },
        style: {
          fontSize: ((u = (l = o.typography) == null ? void 0 : l.pxToRem) == null ? void 0 : u.call(l, 20)) || "1.25rem"
        }
      },
      {
        props: {
          fontSize: "medium"
        },
        style: {
          fontSize: ((g = (a = o.typography) == null ? void 0 : a.pxToRem) == null ? void 0 : g.call(a, 24)) || "1.5rem"
        }
      },
      {
        props: {
          fontSize: "large"
        },
        style: {
          fontSize: ((d = (p = o.typography) == null ? void 0 : p.pxToRem) == null ? void 0 : d.call(p, 35)) || "2.1875rem"
        }
      },
      // TODO v5 deprecate color prop, v6 remove for sx
      ...Object.entries((o.vars ?? o).palette).filter(([, i]) => i && i.main).map(([i]) => {
        var S, v;
        return {
          props: {
            color: i
          },
          style: {
            color: (v = (S = (o.vars ?? o).palette) == null ? void 0 : S[i]) == null ? void 0 : v.main
          }
        };
      }),
      {
        props: {
          color: "action"
        },
        style: {
          color: (m = (c = (o.vars ?? o).palette) == null ? void 0 : c.action) == null ? void 0 : m.active
        }
      },
      {
        props: {
          color: "disabled"
        },
        style: {
          color: (f = (y = (o.vars ?? o).palette) == null ? void 0 : y.action) == null ? void 0 : f.disabled
        }
      },
      {
        props: {
          color: "inherit"
        },
        style: {
          color: void 0
        }
      }
    ]
  };
})), x = /* @__PURE__ */ h.forwardRef(function(r, n) {
  const s = R({
    props: r,
    name: "MuiSvgIcon"
  }), {
    children: t,
    className: l,
    color: u = "inherit",
    component: a = "svg",
    fontSize: g = "medium",
    htmlColor: p,
    inheritViewBox: d = !1,
    titleAccess: c,
    viewBox: m = "0 0 24 24",
    ...y
  } = s, f = /* @__PURE__ */ h.isValidElement(t) && t.type === "svg", i = {
    ...s,
    color: u,
    component: a,
    fontSize: g,
    instanceFontSize: r.fontSize,
    inheritViewBox: d,
    viewBox: m,
    hasSvgAsChild: f
  }, S = {};
  d || (S.viewBox = m);
  const v = A(i);
  return /* @__PURE__ */ w(B, {
    as: a,
    className: $(v.root, l),
    focusable: "false",
    color: p,
    "aria-hidden": c ? void 0 : !0,
    role: c ? "img" : void 0,
    ref: n,
    ...S,
    ...y,
    ...f && t.props,
    ownerState: i,
    children: [f ? t.props.children : t, c ? /* @__PURE__ */ b("title", {
      children: c
    }) : null]
  });
});
process.env.NODE_ENV !== "production" && (x.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * Node passed into the SVG element.
   */
  children: e.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: e.object,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * The color of the component.
   * It supports both default and custom theme colors, which can be added as shown in the
   * [palette customization guide](https://mui.com/material-ui/customization/palette/#custom-colors).
   * You can use the `htmlColor` prop to apply a color attribute to the SVG element.
   * @default 'inherit'
   */
  color: e.oneOfType([e.oneOf(["inherit", "action", "disabled", "primary", "secondary", "error", "info", "success", "warning"]), e.string]),
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: e.elementType,
  /**
   * The fontSize applied to the icon. Defaults to 24px, but can be configure to inherit font size.
   * @default 'medium'
   */
  fontSize: e.oneOfType([e.oneOf(["inherit", "large", "medium", "small"]), e.string]),
  /**
   * Applies a color attribute to the SVG element.
   */
  htmlColor: e.string,
  /**
   * If `true`, the root node will inherit the custom `component`'s viewBox and the `viewBox`
   * prop will be ignored.
   * Useful when you want to reference a custom `component` and have `SvgIcon` pass that
   * `component`'s viewBox to the root node.
   * @default false
   */
  inheritViewBox: e.bool,
  /**
   * The shape-rendering attribute. The behavior of the different options is described on the
   * [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/shape-rendering).
   * If you are having issues with blurry icons you should investigate this prop.
   */
  shapeRendering: e.string,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: e.oneOfType([e.arrayOf(e.oneOfType([e.func, e.object, e.bool])), e.func, e.object]),
  /**
   * Provides a human-readable title for the element that contains it.
   * https://www.w3.org/TR/SVG-access/#Equivalent
   */
  titleAccess: e.string,
  /**
   * Allows you to redefine what the coordinates without units mean inside an SVG element.
   * For example, if the SVG element is 500 (width) by 200 (height),
   * and you pass viewBox="0 0 50 20",
   * this means that the coordinates inside the SVG will go from the top left corner (0,0)
   * to bottom right (50,20) and each unit will be worth 10px.
   * @default '0 0 24 24'
   */
  viewBox: e.string
});
x.muiName = "SvgIcon";
const I = x;
function D(o, r) {
  function n(s, t) {
    return /* @__PURE__ */ b(I, {
      "data-testid": `${r}Icon`,
      ref: t,
      ...s,
      children: o
    });
  }
  return process.env.NODE_ENV !== "production" && (n.displayName = `${r}Icon`), n.muiName = I.muiName, /* @__PURE__ */ h.memo(/* @__PURE__ */ h.forwardRef(n));
}
export {
  D as c
};

/** 
 Copyright Highway9 Networks Inc. 
 */ 
 import React, { useEffect, useState } from "react";
 import IconButton from "@mui/material/IconButton";
 import CloseIcon from "@mui/icons-material/Close";
 import { Box, Button, Grid, Alert } from "@mui/material";
 
 type Error = { message: string };
 
 type ErrorsComponentProps = {
   errorsData?: Error[];
   warningsData?: Error[];
   closeAlert: (index: number, type: string) => void;
   closeAll: () => void;
 };
 
 const ErrorsComponent: React.FC<ErrorsComponentProps> = ({ errorsData = [], warningsData = [], closeAlert, closeAll }) => {
   const [errors, setErrors] = useState<Error[]>(errorsData);
   const [warnings, setWarnings] = useState<Error[]>(warningsData);
   useEffect(() => {
     setErrors(errorsData);
     setWarnings(warningsData);
   }, [errorsData, warningsData]);
 
   return (
     <Grid sx={{
       width: "100%",
       pb: 1,
       "& > * + *": {
         mt: 2,
       },
     }}>
       {errors
         ? errors.map((item, index) => (
             <Alert
               key={index}
               severity="error"
               action={
                 <IconButton
                   aria-label="close"
                   color="inherit"
                   size="small"
                   onClick={() => {
                     closeAlert(index, "error");
                   }}
                 >
                   <CloseIcon fontSize="inherit" />
                 </IconButton>
               }
             >
               {item.message}
             </Alert>
           ))
         : null}
       {warnings
         ? warnings.map((item, index) => (
             <Alert
               key={index}
               severity="warning"
               action={
                 <IconButton
                   aria-label="close"
                   color="inherit"
                   size="small"
                   onClick={() => {
                     closeAlert(index, "warning");
                   }}
                 >
                   <CloseIcon fontSize="inherit" />
                 </IconButton>
               }
             >
               {item.message}
             </Alert>
           ))
         : null}
       {((errors && errors?.length + warnings?.length > 1) || (warnings && errors?.length + warnings?.length > 1)) && (
         <Box display="flex" justifyContent="right">
           <Button color="primary" onClick={closeAll}>
             Close All
           </Button>
         </Box>
       )}
     </Grid>
   );
 };
 
 export default ErrorsComponent;
 
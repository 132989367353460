/**
 * Copyright 2021-2023 Highway9 Networks Inc.
 */
import { Action, AnyAction, Dispatch, MiddlewareAPI, configureStore } from "@reduxjs/toolkit";
import tenantSlice from "./context/tenantSlice";
import serviceInstanceSlice from "./context/serviceInstanceSlice";
import partnerSlice from "./context/partnerSlice";
import hardwareSlice from "./context/hardwareSlice";
import loadingSlice from "./context/loadingSlice";
import utilitySlice from "./context/utilitySlice";
import productSlice from "./context/productSlice";
import deviceManagementSlice from "./context/deviceManagementSlice";
import userSlice from "./context/userSlice";
import certificateSlice from "./context/mocngwCertificateSlice";

export const store = configureStore({
  reducer: {
    tenant: tenantSlice,
    serviceInstance: serviceInstanceSlice,
    partner: partnerSlice,
    hardware: hardwareSlice,
    loading: loadingSlice,
    utility: utilitySlice,
    product: productSlice,
    devices: deviceManagementSlice,
    user:userSlice,
    certificate:certificateSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = Action | ((dispatch: AppDispatch, getState: () => RootState) => ReturnType);
// Redux Middleware
export interface ReduxMiddleware<
  S = any, // type of the state supported by this middleware.
  D extends Dispatch = Dispatch, // type of the dispatch supported by this middleware.
  A extends Action = AnyAction, // type of the action(s) supported by this middleware, inferred from actions passed to applyMiddleware
  DispatchExt = {} // optional override return behavior of dispatch
> {
  (api: MiddlewareAPI<D, S>): (next: D) => (action: A) => A | DispatchExt;
}

import { r as a, m as u, a as c } from "./resolveComponentProps.js";
import { u as m } from "./utils.js";
import '../assets/style.css';function q(o) {
  var t;
  const {
    elementType: n,
    externalSlotProps: _,
    ownerState: s,
    skipResolvingSlotProps: l = !1,
    ...r
  } = o, e = l ? {} : a(_, s), {
    props: p,
    internalRef: i
  } = u({
    ...r,
    externalSlotProps: e
  }), d = m(i, e == null ? void 0 : e.ref, (t = o.additionalProps) == null ? void 0 : t.ref);
  return c(n, {
    ...p,
    ref: d
  }, s);
}
const v = "_value_1dse4_3", f = "_valueText_1dse4_42", h = "_label_1dse4_47", P = "_multiChips_1dse4_57", S = "_chip_1dse4_64", b = "_menu_1dse4_80", g = "_options_1dse4_84", x = "_input_1dse4_101", T = "_error_1dse4_129", C = "_disabled_1dse4_133", R = "_group_1dse4_142", k = "_option_1dse4_84", w = "_selected_1dse4_169", y = "_icon_1dse4_173", z = {
  value: v,
  "value-outlined": "_value-outlined_1dse4_19",
  "value-filled": "_value-filled_1dse4_27",
  "value-none": "_value-none_1dse4_35",
  valueText: f,
  label: h,
  multiChips: P,
  chip: S,
  menu: b,
  options: g,
  input: x,
  error: T,
  disabled: C,
  group: R,
  option: k,
  selected: w,
  icon: y
};
export {
  z as s,
  q as u
};
